import { extend, localize } from 'vee-validate'
import {
  required as ruleRequired,
  email as ruleEmail,
  min as rule_min,
  max as rule_max,
  min_value as rule_min_value,
  max_value as rule_max_value,
  confirmed as ruleConfirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  numeric as rule_numeric,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_spaces as rule_alpha_spaces,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'
import id from 'vee-validate/dist/locale/id.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorResetpasswordRules,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', ruleRequired)

export const email = extend('email', ruleEmail)

export const min = extend('min', rule_min)

export const minValue = extend('min_value', rule_min_value)

export const maxValue = extend('max_value', rule_max_value)

export const confirmed = extend('confirmed', ruleConfirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const max = extend('max', rule_max)

export const alpha_spaces = extend('alpha_spaces', rule_alpha_spaces) // eslint-disable-line

export const numeric = extend('numeric', rule_numeric)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

// Reset Password
export const resetpasswordrequired = extend('resetpasswordrequired', {
  ...ruleRequired,
  message: 'Masukan password baru Kamu.',
})
export const resetconfirmpasswordrequired = extend('resetconfirmpasswordrequired', {
  ...ruleRequired,
  message: 'Konfirmasi password baru Kamu.',
})
export const resetpasswordconfirm = extend('resetpasswordconfirm', {
  ...ruleConfirmed,
  message: 'Maaf, password tidak sesuai.',
})
export const resetpasswordrules = extend('resetpasswordrules', {
  validate: validatorResetpasswordRules,
  message: 'minimal 8 karakter yang terdiri dari huruf kapital, angka, dan spesial karakter',
})

// Forgot Password
export const forgotpasswordEmail = extend('forgotpasswordEmail', {
  ...ruleEmail,
  message: 'Format email yang Kamu masukan salah.',
})
// Install English and Arabic localizations.
localize('id', {
  messages: id.messages,
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
